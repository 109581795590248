import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import HeaderSection from "../components/HeaderSection";
import SEO from "../components/SEO";
import ContentJustified from "../components/ContentJustified";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const CareerPageTemplate = ({
  title,
  content,
  heading,
  description,
  coaching,
  offer,
  location,
  commitment,
}) => {
  const theme = useTheme();

  return (

    <Container maxWidth="lg">
      <HeaderSection
        header={heading}
        subheader={description}
        location={location}
      />



      {coaching.coaches.map((coach, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          justifyContent="center"
          flexDirection="column"

        >
          <Grid
            container
            item
            xs={10}
            sm={3}
            style={{ maxWidth: 180 }}

          >

            <PreviewCompatibleImage
              imageInfo={{
                image: coach.image,
                alt: `${coach.shortName} image`,
              }} />
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            alignContent="flex-start"
            item
            xs={12}
            sm={6}

          >
            <Typography
              component="h3"
              variant="h4"
              color="secondary"
              align="center"
            >
              {coach.shortName}
            </Typography>
            <Typography
              component="p"
              variant="body1"
            // color="secondary"
            // align="center"
            >
              {coach.description}
            </Typography>

          </Grid>
        </Grid >

      ))}

      <ContentJustified content={content} />

    </Container >
  );
};

// CareerPageTemplate.propTypes = {
//   image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//   title: PropTypes.string,
//   heading: PropTypes.string,
//   description: PropTypes.string,
//   intro: PropTypes.shape({
//     blurbs: PropTypes.array,
//   }),
// };

const CareerPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        location={location}
        description={
          "Our career coaches will guide you and support you during your job search"
        }
      />

      <CareerPageTemplate
        content={html}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        coaching={frontmatter.coaching}
        offer={frontmatter.offer}
        commitment={frontmatter.commitment}
        location={location}
      />
    </Layout>
  );
};

CareerPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CareerPage;

export const careerPageQuery = graphql`
  query CareerPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        description
        coaching {
          title
          coaches {
            name
            description
            shortName
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        offer {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        commitment {
          title
          text
        }
      }
    }
  }
`;
